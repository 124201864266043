<div class="uitk-l-grid claim-list-container">
  <div class="uitk-l-grid__row">
    <div class="uitk-l-grid__col--12">
      <div class="uitk-c-table__caption claim__header">
        <h1>{{ tableTitle }}</h1>
        <div *ngIf="currentClient && isDataPresent && !isLoading">
          <img class="claim__header__img" [src]="currentClient.clientLogo"
            alt="currentClient.clientName" />
        </div>
      </div>
      <div class="claim__list" *ngIf="claims$ | async as claims">
        <ng-container *ngIf="!isLoading else loading">
          <div class="claim__table">
            <div class="uitk-u-padding-bottom--base">
              <button
                id="show-search-btn"
                class="toggle-search-button"
                (click)="showSearchPrepay()"
                uitkFilterToggleButton
                [isExpanded]="showSearch"
                [disabled]="isSearchClaimsEnabled"
                [hideLabelText]="'Close Search'"
                [showLabelText]="'Show Search'"
              ></button>
              <button *ngIf="hasClaimsData"
                id="exportClaimDialog"
                class="uitk-c-button uitk-c-button--default uitk-u-margin-right--sm export-claim-button"
                (click)="showExportClaimDialog()"
              >
                {{ exportClaimsButtonText }}
                <span class="uitk-u-element--hidden"> Opens modal window</span>
              </button>
              <uitk-dialog
                [(showModal)]="isExportDialogModalEnabled"
                uitkId="myDialog-1"
                triggerElementId="myDialog-1_openBtn"
            >
                <uitk-dialog-header>
                  <span class="uitk-icon uitk-icon-export lg-icon" aria-hidden="true"></span>
                    {{ exportDialogHeaderText }}
                </uitk-dialog-header>
                <uitk-dialog-content>
                    <p>{{ exportClaimsUserMessage }}</p>
                </uitk-dialog-content>
                <uitk-dialog-actions>
                    <button class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs"
                     (click)="exportPrepayClaims(selectedFilterKey)">
                        {{ downloadButtonText }}
                    </button> 
                </uitk-dialog-actions>
              </uitk-dialog>
            </div>
            <div *ngIf="showSearch" class="uitk-u-filter___panel" >
              <!--09/27/24 - jpark109: Temporarily disabling the search functionality. We will be enabling this feature in a future story while we fix the security auth pattenr for this endpoint -->
              <!-- <form [formGroup]="searchClaimsForm" (ngSubmit)="searchPrepayClaims(selectedSearchKey)" class ="searchFilter" [ngClass]="{'disabled': !hasClaimsData}">
                <legend class="uitk-u-h3 uitk-u-margin-bottom--base" >{{ searchHeaderText }}</legend>
                <div class="searchform">
                  <uitk-form-field class="search-form-field">
                      <uitk-dropdown
                          id="search-drop-down"
                          [items]="searchKeyList"
                          fieldsetLabel="Fieldset Legend"
                          formControlName="searchDropdown"
                          (onChange)="onSearchKeySelectChange($event)" 
                          (onClose)="onCloseSearchKeyDropdown()"
                          [enableSelectFlag]="false"
                          [showSelectAll]="false"
                          [isMultiSelect]="false"
                          [itemSortOn]="true"
                      >
                      </uitk-dropdown>
                      <uitk-form-field-error
                          *ngIf="
                              (searchClaimsForm.controls['searchDropdown'].errors?.required ||
                                  this.closeSearchKeyDropdown) &&
                              (searchClaimsForm.controls['searchDropdown'].dirty || this.closeSearchKeyDropdown) &&
                              (!searchClaimsForm.value.searchDropdown.value ||
                              searchClaimsForm.value.searchDropdown.value === null) &&
                              isClearSearchEnabled
                          "
                      >
                          {{requiredErrorText}}
                      </uitk-form-field-error>
                  </uitk-form-field>
                  <uitk-form-field class="search-form-field">
                    <input 
                      id="search-text-box"
                      uitkInput
                      formControlName="searchValue"
                      (keyup)="onTextBoxChange($event)" 
                      required
                    />
                    <uitk-form-field-error 
                      *ngIf="
                      isSearchClaimsEnabled === true &&
                      searchClaimsForm?.controls?.searchValue?.errors?.required ||
                      searchClaimsForm?.controls?.searchValue?.dirty &&
                      searchClaimsForm?.controls?.searchValue?.invalid
                      "
                    >
                      {{ requiredErrorText }}
                    </uitk-form-field-error>
                  </uitk-form-field>
                </div>
              
                <div class="uitk-u-padding-top--base">
                  <button
                    #applyFiltersBtn
                    type="submit" [disabled]="!searchClaimsForm.valid"
                    class="uitk-c-button uitk-c-button--default uitk-u-margin-right--sm"
                  >
                    {{ searchPrepayText }}
                  </button>
                  <button
                    [disabled]="!isClearSearchEnabled"
                    type="button"
                    class="uitk-c-button uitk-c-button--ghost clear-filter-btn"
                    (click)="clearSearchPrepay()"
                  >
                    {{ clearSearchPrepayText }}
                  </button>
                </div>
                <div *ngIf="!isDataPresent && isClearSearchEnabled" class="errorMessage">
                    {{ noClaimsMatchMessage }}
                </div>
              </form> -->
        
              <form [formGroup]="filterClaimsForm" (ngSubmit)="filterPrepayClaims(selectedFilterKey)"  class ="statusFilter" >
                <legend class="uitk-u-h3 uitk-u-margin-bottom--base">{{ filterHeaderText }}</legend>
                <div class="filterForm">
                  <uitk-form-field class="filter-form-field">
                    <uitk-dropdown
                        id="filter-drop-down"
                        [items]="filterKeyList"
                        fieldsetLabel="Status Type"
                        formControlName="filterDropdown"
                        (onChange)="onFilterKeySelectChange($event)" 
                        [enableSelectFlag]="false"
                        [showSelectAll]="true"
                        [isMultiSelect]="false"
                        [itemSortOn]="true"
                    >
                    </uitk-dropdown>
                </uitk-form-field>
                </div>
              
                <div class="uitk-u-padding-top--base">
                  <button
                  #applyFiltersBtn
                  type="submit"
                  class="uitk-c-button uitk-c-button--default uitk-u-margin-right--sm"
                >
                  {{ filterText }}
                </button>
                </div>
                <div *ngIf="!isDataPresent && isSearchClaimsEnabled" class="errorMessage">
                    {{ noClaimsMatchMessage }}
                </div>
              </form>
            </div>
            <div *ngIf="!isDataPresent && !isSearchClaimsEnabled && !isClearSearchEnabled && showNoClaimsStatusMessage" class="uitk-l-grid__col centered__shadowed">
              <legend>{{ noClaimsStatusMessage }}</legend>
            </div>
            <div class="uitk-c-table--responsive" *ngIf="hasClaimsData">
              <uitk-pagination [paginationConfig]="paginationConfig" [entriesPerPage]="entriesPerPage"
                [enableGoToPage]="true" [totalRecordsCount]="totalRecordsCount"
                (onPaginationChange)="onPaginationChangeHandler($event)">
                <table uitkTable [dataSource]="dataSource.data" id="basic-table" class="uitk-c-table"
                  aria-labelledby="table-paginate-example" aria-describedby="row-data">
                  <thead class="uitk-c-table__head">
                    <tr uitkTableSort #sortTable="uitkTableSort">
                      <th
                        uitkTableSortColumn
                        [column]="headerName.id"
                        (sortChange)="onSortChange($event)"
                        *ngFor="let headerName of tableHeader" scope="col"
                        [ngClass]="headerName.className ? headerName.className : ''">
                          {{ headerName.name }}
                      </th>
                    </tr>
                  </thead>
                  <tbody id="row-data" class="uitk-c-table__body">
                    <ng-container *uitkTableRowBody="let claim">
                      <tr class="uitk-c-table__row">
                        <td>{{ claim.claimNumber }}</td>
                        <td>{{ claim.patientAccountNumber }}</td>
                        <td class= "wordWrapTableCell">
                          {{ claim.recipientLastName }},
                          {{ claim.recipientFirstName  }}
                          {{ claim.recipientMiddleInitial  }}
                        </td>
                        <td>{{ claim.tin }}</td>
                        <td>{{ claim.medicalRecordRequestDate }}</td>
                        <td>{{ claim.patientDoB }}</td>
                        <td>{{ claim.startDateOfService }}</td>
                        <td class= "wordWrapTableCell">{{ claim.providerName }}</td>
                        <td>
                          <span *ngIf= "claim.mrStatus===0 && claim.letterType==='I'">
                            <span *ngIf = "!isUploading(claim) else uploadingIcon" class="uitk-icon uitk-icon-upload sm-icon" (click)="showUploadDialog(claim)"></span>
                            <ng-template #uploadingIcon>
                              <uitk-tooltip
                              uitkId="upload-tooltip"
                              [tooltipContent]="tooltipText"
                              tooltipPlacement="bottom">
                              <span class="uitk-icon uitk-icon-process sm-icon"></span>
                            </uitk-tooltip>
                            </ng-template>
                          </span>
                        </td>
                        <td><span class="uitk-icon uitk-icon-list sm-icon" (click)="showClaimInfoDialog(claim)"></span></td>
                      </tr>
                    </ng-container>
                  </tbody>
                </table>
              </uitk-pagination>
              <app-upload-files (updatePage)="updateClaimsPage()"></app-upload-files>
              <app-document-info></app-document-info>
            </div>
          </div>
        </ng-container>
        <ng-template #loading>
          <app-loading-spinner [loadingMessage]="claimsLoadingMessage" [spinnerSize]="spinnerSize"></app-loading-spinner>
        </ng-template>
      </div>
    </div>
  </div>
</div>