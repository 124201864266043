import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { GlobalConstants } from '../constants/GlobalConstants';
import LetterForm from '../models/LetterForm';
import { AuthService } from '../services/abstracts/auth.service';
import { ClaimService } from '../services/claim.service';
import { SendVerificationEmailService } from '../services/send-verification-email.service';
import VerificationEmailData from '../models/VerificationEmailData';
import User from '../models/User';

@Component({
  selector: 'app-self-registration-form',
  templateUrl: './self-registration-form.component.html',
  styleUrls: ['./self-registration.component.scss']
})
export class SelfRegistrationFormComponent {
  private window = window;
  tinLabelText: string;
  tinRequiredText: string;
  formError = false;
  verificationLoading = false;
  verificationErrorMessage: string;
  emailVerificationErrorMessage: string;
  visibleLoadingText: string;
  authorizationLabelText: string;
  recordBarCodeLabelText: string;
  firstDateOfServiceLabelText: string;
  authorizationCodeRequiredText: string;
  recordBarCodeRequiredText: string;
  firstDateOfServiceRequiredText: string;
  dateInvalidText: string;
  selfRegistrationActivatedNotice: string;
  selfRegistrationRedirectNotice: string;
  selfRegistrationApprovalNotice: string;
  notificationContent: string;
  isWarningVisible: boolean;
  isErrorVisible: boolean;
  isSelfRegistration: boolean;
  tinHelpText: string;
  authorizationHelpText: string;
  barCodeHelpText: string;
  dateOfServiceHelpText: string;
  user: User;

  constructor(private claimService: ClaimService, private ref: ChangeDetectorRef, private readonly sendVerificationEmailService: SendVerificationEmailService, private readonly authService: AuthService) {
    this.selfRegistrationActivatedNotice = GlobalConstants.SELF_REGISTRATION_ACTIVATION_NOTICE;
    this.selfRegistrationApprovalNotice = GlobalConstants.SELF_REGISTRATION_APPROVAL_NOTICE;
    this.selfRegistrationRedirectNotice = GlobalConstants.SELF_REGISTRATION_REDIRECT_NOTICE;
    this.authorizationLabelText = GlobalConstants.AUTHORIZATION_LABEL_TEXT
    this.recordBarCodeLabelText = GlobalConstants.RECORD_BARCODE_LABEL_TEXT
    this.firstDateOfServiceLabelText = GlobalConstants.FIRST_DATE_OF_SERVICE_LABEL_TEXT
    this.authorizationCodeRequiredText = GlobalConstants.AUTHORIZATION_CODE_REQUIRED_TEXT
    this.recordBarCodeRequiredText = GlobalConstants.RECORD_BARCODE_REQUIRED_TEXT
    this.firstDateOfServiceRequiredText = GlobalConstants.FIRST_DATE_OF_SERVICE_REQUIRED_TEXT
    this.tinLabelText = GlobalConstants.TIN_LABEL_TEXT;
    this.dateInvalidText = GlobalConstants.DATE_FORMAT_TEXT;
    this.tinRequiredText = GlobalConstants.TIN_REQUIRED_TEXT;
    this.tinHelpText = GlobalConstants.TIN_HELP_TEXT;
    this.authorizationHelpText = GlobalConstants.AUTHORIZATION_HELP_TEXT;
    this.barCodeHelpText = GlobalConstants.BAR_CODE_HELP_TEXT
    this.dateOfServiceHelpText = GlobalConstants.DATE_OF_SERVICE_HELP_TEXT;
    this.visibleLoadingText = GlobalConstants.VERIFICATION_LOADING_TEXT;
    this.verificationErrorMessage = GlobalConstants.VERIFICATION_ERROR_MESSAGE;
    this.emailVerificationErrorMessage = GlobalConstants.EMAIL_VERIFICATION_ERROR_MESSAGE;
  }

  inputForm = new FormGroup({
    authorizationCode: new FormControl('', { validators: Validators.required, updateOn: 'blur' }),
    tin: new FormControl('', { validators: Validators.required, updateOn: 'blur' }),
    memberLetterBarCode: new FormControl('', { validators: Validators.required, updateOn: 'blur' }),
    startDateOfService: new FormControl('', { updateOn: 'change' }),
    userEmail: new FormControl('')
  });

  ngOnInit() {
    this.user = this.authService.getUserProfile();
  }

  /**
  * This function will redirect the window to the Guardian Self Registration url
  */
  handleSelfRegistrationProcess(): void {
    this.inputForm.get('userEmail').setValue(this.user.email);
    let letter: LetterForm = this.inputForm.value;
    let verificationEmailData: VerificationEmailData = { user: this.user, letterForm: letter };
    this.verificationLoading = true;
    this.claimService.verifyLetter(letter, async res => {
      if (res) {      
        this.callSendEmailService(verificationEmailData);
      }
    }, error => {
      this.formError = true;
      this.verificationLoading = false;
      console.log(error);
      this.ref.detectChanges();
    });
  }

  /**
  * This function will send an email to the ops team that a user has been verified
  */
  private callSendEmailService(verificationEmailData: VerificationEmailData): void {
    this.sendVerificationEmailService.sendVerificationEmail(verificationEmailData, () => {
      this.formError = false;
      this.verificationLoading = false;
      this.window.location.href = environment.selfRegisterUrl;
    }, err => {
      this.verificationErrorMessage = this.emailVerificationErrorMessage;
      this.formError = true;
      this.verificationLoading = false;
      console.log(err);
      this.ref.detectChanges();
    });
  }
}