export const environment = {
  production: true,
  isLocal: true,
  feature_flags: {
    recordUpload: true,
  },
  guardian: {
    optumGuardianUrl: '/auth'
  },
  sentinel: {
    url: 'https://idx-stage.linkhealth.com/auth',
    realm: 'developer-platform',
    clientId: 'paymentintegrity'
  },
  mrUpload: {
    mrUploadUrl: '/backend'
  },
  consentMgmt: {
    preferenceUrl: '/preference'
  },
  dataBankUploadUrl: 'https://sftp.databankimx.com/form/RecordUploadService?ID=0015',
  selfRegisterUrl: 'https://developer-stage.linkhealth.com/apps/security/PIP/selfRegistration',
  sentinelLogOutUrl: 'https://idx.linkhealth.com/auth/realms/developer-platform/protocol/openid-connect/logout?post_logout_redirect_uri=https://paymentintegrityportal-stage.optum.com/logout',
  timeOutDuration: 600000,
  lettersInventory: {
    baseUrl: 'https://lettersinventory-gateway-stage.optum.com/api',
    endpointGetClients: '/claims/clients?',
    endpointGetClaims: '/claims?',
    endpointUpdateClaimStatus: '/claims/status?',
    endpointSearchClaims: '/claims/search?',
    endpointVerificationClaims: '/claims/verification?',
    endpointManualUpload: '/upload',
    endpointClaimsUpload: '/upload/claims'
  },
  url: 'https://paymentintegrityportal-stage.optum.com'
};
